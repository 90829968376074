import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const Paragraph = ({ text, centerText, customClass, onClick, style, children, dataTest, isTruncate = false }) => {
    const [showTitle, setShowTitle] = useState(false);
    const paragraphRef = useRef(null);

    useEffect(() => {
        if (isTruncate && paragraphRef.current) {
            const containerWidth = paragraphRef.current.clientWidth;
            const textWidth = paragraphRef.current.scrollWidth;

            if (textWidth > containerWidth) {
                setShowTitle(true);
            } else {
                setShowTitle(false);
            }
        }
    }, [text, isTruncate]);

    return (
        <p
            style={style}
            ref={paragraphRef}
            onClick={onClick}
            title={showTitle ? text : ''}
            className={`paragraph typography-text ${centerText ? 'center-text' : ''} ${customClass ? customClass : ''} ${
                isTruncate ? 'truncate' : ''
            } `}
            data-test={dataTest}>
            {text}
            {children}
        </p>
    );
};

Paragraph.propTypes = {
    text: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    customClass: PropTypes.string,
    dataTest: PropTypes.string,
    centerText: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func,
    style: PropTypes.object,
    isTruncate: PropTypes.bool
};
Paragraph.defaultProps = {
    text: ''
};

export default Paragraph;
