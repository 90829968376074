import PropTypes from 'prop-types';
import React from 'react';

const Heading3 = ({ text, customClass, children, style, onClick }) => (
    <h3 style={style} className={`heading-three typography-text ${customClass}`} onClick={onClick}>
        {text}
        {children}
    </h3>
);

Heading3.propTypes = {
    text: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node
};

export default Heading3;
