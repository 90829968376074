import gql from 'graphql-tag';

const signup = gql`
    mutation signup($input: UserSignUpInput!) {
        signup(input: $input) {
            access_token
            refresh_token
        }
    }
`;

const addPupilForParent = gql`
    mutation addPupil($input: PupilInput!, $parentId: String!) {
        addPupil(input: $input, parentId: $parentId) {
            fullname
        }
    }
`;

const resetUsersSavedPermissions = gql`
    mutation resetUsersSavedPermissions {
        resetUsersSavedPermissions
    }
`;

const updatePupil = gql`
    mutation updatePupil($input: UpdatePupilInput!) {
        updatePupil(input: $input) {
            id
        }
    }
`;

const updateParent = gql`
    mutation updateParent($input: UpdateParentInput!) {
        updateParent(input: $input) {
            id
        }
    }
`;

const addClassTopic = gql`
    mutation addClassTopic($input: ClassTopicInput!) {
        addClassTopic(input: $input) {
            id
        }
    }
`;

const updateClassTopic = gql`
    mutation updateClassTopic($input: UpdateClassTopicInput!) {
        updateClassTopic(input: $input) {
            id
        }
    }
`;

const deleteClassTopic = gql`
    mutation deleteClassTopic($id: String!) {
        deleteClassTopic(id: $id) {
            status
            msg
        }
    }
`;

const updatePupilMathscot = gql`
    mutation updatePupilMathscot($input: UpdatePupilMathscotInput!) {
        updatePupilMathscot(input: $input) {
            id
        }
    }
`;

const previewPupilMathscotForEditMathscot = gql`
    mutation previewPupilMathscot($input: MathscotChoicesInput!) {
        previewPupilMathscot(input: $input)
    }
`;

const mergeMathscotItems = gql`
    mutation mergeMathscotItems($input: MergeMathscotItemsInput!, $logInput: MergeMathscotItemsLogInput!) {
        mergeMathscotItems(input: $input, logInput: $logInput) {
            bigImage
            thumbnailImage
        }
    }
`;

const createStaticTrophy = gql`
    mutation createStaticTrophy($input: StaticLwpTrophyInput!, $file: [Upload!]) {
        createStaticTrophy(file: $file, input: $input) {
            id
        }
    }
`;

const addSchoolDate = gql`
    mutation addSchoolDate($input: AddSchoolDateInput!) {
        addSchoolDate(input: $input) {
            id
        }
    }
`;

const updateSchoolDate = gql`
    mutation updateSchoolDate($input: UpdateSchoolDateInput!) {
        updateSchoolDate(input: $input) {
            id
        }
    }
`;

const deleteSchoolDate = gql`
    mutation deleteSchoolDate($id: String!) {
        deleteSchoolDate(id: $id) {
            status
            msg
        }
    }
`;

const addSchoolHoliday = gql`
    mutation addSchoolHoliday($input: AddSchoolHolidayInput!) {
        addSchoolHoliday(input: $input) {
            id
        }
    }
`;

const updateSchoolHoliday = gql`
    mutation updateSchoolHoliday($input: UpdateSchoolHolidayInput!) {
        updateSchoolHoliday(input: $input) {
            id
        }
    }
`;

const releaseHolidayToAllSchools = gql`
    mutation releaseHolidayToAllSchools($id: String!) {
        releaseHolidayToAllSchools(id: $id)
    }
`;

const softDeleteSchoolHoliday = gql`
    mutation softDeleteSchoolHoliday($id: String!) {
        softDeleteSchoolHoliday(id: $id) {
            status
            msg
        }
    }
`;

const restoreSchoolHoliday = gql`
    mutation restoreSchoolHoliday($id: String!) {
        restoreSchoolHoliday(id: $id) {
            status
            msg
        }
    }
`;

const updateStaticTrophy = gql`
    mutation updateStaticTrophy($input: UpdateStaticLwpTrophyInput!, $file: [Upload!]) {
        updateStaticTrophy(file: $file, input: $input) {
            id
        }
    }
`;

const addTeacher = gql`
    mutation addTeacher($input: TeacherInput!) {
        addTeacher(input: $input) {
            id
        }
    }
`;

const createSchoolClass = gql`
    mutation createSchoolClass($input: SchoolClassInput!) {
        createSchoolClass(input: $input) {
            id
        }
    }
`;

const likePupilActivityFeedback = gql`
    mutation likePupilActivityFeedback($input: LikePupilActivityFeedbackInput!) {
        likePupilActivityFeedback(input: $input) {
            id
        }
    }
`;

const savePupilActivityRating = gql`
    mutation savePupilActivityRating($id: String!, $rating: Float!) {
        savePupilActivityRating(id: $id, rating: $rating) {
            id
            rating
            earnedStar
        }
    }
`;

const updateTeacher = gql`
    mutation updateTeacher($input: UpdateTeacherInput!) {
        updateTeacher(input: $input) {
            id
        }
    }
`;

const updateAdmin = gql`
    mutation updateAdmin($input: UpdateAdminInput!) {
        updateAdmin(input: $input) {
            id
        }
    }
`;

const inviteTeacher = gql`
    mutation inviteTeacher($input: InviteTeacherInput!) {
        inviteTeacher(input: $input) {
            id
        }
    }
`;

const updateSchoolClass = gql`
    mutation updateSchoolClass($input: UpdateSchoolClassInput!) {
        updateSchoolClass(input: $input) {
            id
        }
    }
`;

const updateSchoolClassReadingSettings = gql`
    mutation updateSchoolClassReadingSettings($input: UpdateSchoolClassReadingSettingsInput!) {
        updateSchoolClassReadingSettings(input: $input) {
            id
        }
    }
`;

const updateSchool = gql`
    mutation updateSchool($input: UpdateSchoolInput!, $file: [Upload!]) {
        updateSchool(input: $input, file: $file) {
            id
        }
    }
`;

const changePassword = gql`
    mutation changePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            id
        }
    }
`;

const savePhotoPermissions = gql`
    mutation savePhotoPermissions($id: String!, $sharingAllowed: Boolean) {
        savePhotoPermissions(id: $id, sharingAllowed: $sharingAllowed) {
            id
        }
    }
`;

const changeClassPupilsPremium = gql`
    mutation changeClassPupilsPremium($input: [ChangeClassPupilPremiumInput!]!) {
        changeClassPupilsPremium(input: $input)
    }
`;

const inviteParentWithPupil = gql`
    mutation inviteParentWithPupil($input: InviteParentWithPupil!) {
        inviteParentWithPupil(input: $input) {
            id
        }
    }
`;

const increaseAccessCount = gql`
    mutation increaseAccessCount($pupilActivityId: String!) {
        increaseAccessCount(pupilActivityId: $pupilActivityId) {
            id
        }
    }
`;

const saveOwnPupilActivityFeedback = gql`
    mutation saveOwnPupilActivityFeedback($file: [Upload!], $input: PupilOwnActivityFeedbackInput!) {
        saveOwnPupilActivityFeedback(file: $file, input: $input) {
            id
        }
    }
`;

const addContact = gql`
    mutation addContact($input: ContactInput!) {
        addContact(input: $input) {
            id
        }
    }
`;

const addAdmin = gql`
    mutation addAdmin($input: AdminInput!) {
        addAdmin(input: $input) {
            link
            user {
                id
            }
        }
    }
`;

const addSchool = gql`
    mutation addSchool($input: SchoolInput!, $file: [Upload!]) {
        addSchool(input: $input, file: $file) {
            id
        }
    }
`;

const addAdminQuery = gql`
    mutation addAdminQuery($input: AdminQueryInput!) {
        addAdminQuery(input: $input) {
            id
        }
    }
`;

const updateAdminQuery = gql`
    mutation updateAdminQuery($input: UpdateAdminQueryInput!) {
        updateAdminQuery(input: $input) {
            id
        }
    }
`;

const addFAQ = gql`
    mutation addFAQ($input: FAQInput!) {
        addFAQ(input: $input) {
            id
        }
    }
`;

const updateFAQ = gql`
    mutation updateFAQ($input: UpdateFAQInput!) {
        updateFAQ(input: $input) {
            id
        }
    }
`;

const deleteFAQ = gql`
    mutation deleteFAQ($id: String!) {
        deleteFAQ(id: $id) {
            status
        }
    }
`;

const deleteAdminQuery = gql`
    mutation deleteAdminQuery($id: String!) {
        deleteAdminQuery(id: $id) {
            status
        }
    }
`;

const refreshAllDataQueries = gql`
    mutation refreshAllDataQueries {
        refreshAllDataQueries
    }
`;

const addNews = gql`
    mutation addNews($input: NewsInput!) {
        addNews(input: $input) {
            id
        }
    }
`;

const updateNews = gql`
    mutation updateNews($input: UpdateNewsInput!) {
        updateNews(input: $input) {
            id
        }
    }
`;

const deleteNews = gql`
    mutation deleteNews($id: String!) {
        deleteNews(id: $id) {
            status
        }
    }
`;

const addStaticPeriod = gql`
    mutation addStaticPeriod($input: PeriodInput!) {
        addStaticPeriod(input: $input) {
            index
        }
    }
`;

const updateStaticPeriod = gql`
    mutation updateStaticPeriod($input: UpdatePeriodInput!) {
        updateStaticPeriod(input: $input) {
            index
        }
    }
`;

const deleteStaticPeriod = gql`
    mutation deleteStaticPeriod($index: String!) {
        deleteStaticPeriod(index: $index) {
            status
        }
    }
`;
const removePupilsFromSchool = gql`
    mutation removePupilsFromSchool($pupilIds: [String!]!) {
        removePupilsFromSchool(pupilIds: $pupilIds)
    }
`;

const addYearGroup = gql`
    mutation addYearGroup($input: YearGroupInput!) {
        addYearGroup(input: $input) {
            id
        }
    }
`;

const updateYearGroup = gql`
    mutation updateYearGroup($input: UpdateYearGroupInput!) {
        updateYearGroup(input: $input) {
            id
        }
    }
`;

const deleteYearGroup = gql`
    mutation deleteYearGroup($id: String!) {
        deleteYearGroup(id: $id) {
            status
        }
    }
`;

const acceptUserInvitation = gql`
    mutation acceptUserInvitation($input: AcceptUserInvitationInput!) {
        acceptUserInvitation(input: $input) {
            accessToken
            refreshToken
        }
    }
`;

const acceptOrRejectPupilInvitation = gql`
    mutation acceptOrRejectPupilInvitation($rejected: Boolean!, $pupilId: String!, $parentId: String!) {
        acceptOrRejectPupilInvitation(rejected: $rejected, pupilId: $pupilId, parentId: $parentId) {
            id
        }
    }
`;

const forgotPassword = gql`
    mutation forgotPassword($email: String, $phoneNumber: String) {
        forgotPassword(email: $email, phoneNumber: $phoneNumber)
    }
`;

const resetPasswordByToken = gql`
    mutation resetPasswordByToken($input: ResetPasswordInput!) {
        resetPasswordByToken(input: $input) {
            id
        }
    }
`;

const addResource = gql`
    mutation addResource($file: [Upload!]!, $input: ResourceInput!) {
        addResource(file: $file, input: $input) {
            id
        }
    }
`;

export const updateResource = gql`
    mutation updateResource($file: [Upload!], $input: UpdateResourceInput!) {
        updateResource(file: $file, input: $input) {
            id
        }
    }
`;

export const deleteResource = gql`
    mutation deleteResource($id: String!) {
        deleteResource(id: $id) {
            status
            msg
        }
    }
`;
export const softDeleteSchool = gql`
    mutation softDeleteSchool($id: String!) {
        softDeleteSchool(id: $id) {
            status
            msg
        }
    }
`;
export const softDeletePupilTopic = gql`
    mutation softDeletePupilTopic($id: String!) {
        softDeletePupilTopic(id: $id) {
            status
            msg
        }
    }
`;
export const restorePupilTopic = gql`
    mutation restorePupilTopic($id: String!) {
        restorePupilTopic(id: $id) {
            status
            msg
        }
    }
`;
export const softDeleteFeedback = gql`
    mutation softDeleteFeedback($id: String!) {
        softDeleteFeedback(id: $id) {
            status
            msg
        }
    }
`;
export const restoreFeedback = gql`
    mutation restoreFeedback($id: String!) {
        restoreFeedback(id: $id) {
            status
            msg
        }
    }
`;
export const softDeletePupilActivity = gql`
    mutation softDeletePupilActivity($id: String!) {
        softDeletePupilActivity(id: $id) {
            status
            msg
        }
    }
`;
export const restorePupilActivity = gql`
    mutation restorePupilActivity($id: String!) {
        restorePupilActivity(id: $id) {
            status
            msg
        }
    }
`;
export const softDeleteUser = gql`
    mutation softDeleteUser($id: String!) {
        softDeleteUser(id: $id) {
            status
            msg
        }
    }
`;
export const softDeleteTeacher = gql`
    mutation softDeleteTeacher($id: String!, $schoolId: String!) {
        softDeleteTeacher(id: $id, schoolId: $schoolId) {
            status
            msg
        }
    }
`;
export const softDeleteDemoFeedback = gql`
    mutation softDeleteDemoFeedback($id: String!) {
        softDeleteDemoFeedback(id: $id) {
            status
            msg
        }
    }
`;
export const restoreDemoFeedback = gql`
    mutation restoreDemoFeedback($id: String!) {
        restoreDemoFeedback(id: $id) {
            status
            msg
        }
    }
`;
export const updatePupilActivity = gql`
    mutation updatePupilActivity($id: String!, $earnedStar: Boolean!, $rating: Float) {
        updatePupilActivity(id: $id, earnedStar: $earnedStar, rating: $rating) {
            id
            earnedStar
            rating
        }
    }
`;
export const restoreUser = gql`
    mutation restoreUser($id: String!) {
        restoreUser(id: $id) {
            status
            msg
        }
    }
`;
export const restoreSchool = gql`
    mutation restoreSchool($id: String!) {
        restoreSchool(id: $id) {
            status
            msg
        }
    }
`;
export const softDeleteSchoolClass = gql`
    mutation softDeleteSchoolClass($id: String!) {
        softDeleteSchoolClass(id: $id) {
            status
            msg
        }
    }
`;
export const restoreSchoolClass = gql`
    mutation restoreSchoolClass($id: String!) {
        restoreSchoolClass(id: $id) {
            status
            msg
        }
    }
`;
export const softDeleteClassTopic = gql`
    mutation softDeleteClassTopic($id: String!, $option: ClassTopicSoftDeleteOptions!) {
        softDeleteClassTopic(id: $id, option: $option) {
            status
            msg
        }
    }
`;
export const updatePupilTopic = gql`
    mutation updatePupilTopic($input: UpdatePupilTopic!) {
        updatePupilTopic(input: $input) {
            id
        }
    }
`;

export const restoreClassTopic = gql`
    mutation restoreClassTopic($id: String!) {
        restoreClassTopic(id: $id) {
            status
            msg
        }
    }
`;
export const softDeletePupil = gql`
    mutation softDeletePupil($id: String!) {
        softDeletePupil(id: $id) {
            status
            msg
        }
    }
`;
export const restorePupil = gql`
    mutation restorePupil($id: String!) {
        restorePupil(id: $id) {
            status
            msg
        }
    }
`;

const resetUserPassword = gql`
    mutation resetUserPassword($email: String!) {
        resetUserPassword(email: $email) {
            link
        }
    }
`;

const addTopic = gql`
    mutation addTopic($input: TopicInput!, $file: [Upload!]) {
        addTopic(input: $input, file: $file) {
            id
        }
    }
`;

const updateTopic = gql`
    mutation updateTopic($input: UpdateTopicInput!, $file: [Upload!]) {
        updateTopic(input: $input, file: $file) {
            id
        }
    }
`;

// const verifyEmailAndPhoneById = gql`
//     mutation verifyEmailAndPhoneById($input: VerifyEmailAndPhoneByIdInput!) {
//         verifyEmailAndPhoneById(input: $input)
//     }
// `;

const downloadAllClassesCSV = gql`
    mutation downloadAllClassesCSV {
        downloadAllClassesCSV
    }
`;

const generateCertificates = gql`
    mutation generateCertificates($input: GenerateMathscotCertificateInput!) {
        generateCertificates(input: $input)
    }
`;

const sendNotification = gql`
    mutation sendNotification($input: SendNotificationInput!) {
        sendNotification(input: $input)
    }
`;
const sendTestEmail = gql`
    mutation sendTestEmail($input: TestEmailInput!) {
        sendTestEmail(input: $input) {
            response
            status
        }
    }
`;
const deleteModal = gql`
    mutation deleteModal($id: String!) {
        deleteModal(id: $id) {
            status
        }
    }
`;

const removeParentFromPupil = gql`
    mutation removeParentFromPupil($parentId: String!, $pupilId: String!) {
        removeParentFromPupil(parentId: $parentId, pupilId: $pupilId) {
            id
        }
    }
`;

const deletePupilTrophy = gql`
    mutation deletePupilTrophy($id: String!) {
        deletePupilTrophy(id: $id) {
            status
        }
    }
`;

const inviteParentsOfPupils = gql`
    mutation inviteParentsOfPupils($pupilIds: [String!]!) {
        inviteParentsOfPupils(pupilIds: $pupilIds)
    }
`;

const inviteParentOfPupil = gql`
    mutation inviteParentOfPupil($parentId: String!, $pupilId: String!, $sendOnPhone: Boolean!) {
        inviteParentOfPupil(parentId: $parentId, pupilId: $pupilId, sendOnPhone: $sendOnPhone) {
            id
        }
    }
`;

const pullWondeSchoolData = gql`
    mutation pullWondeSchoolData($lwpSchoolId: String!, $wondeSchoolId: String!) {
        pullWondeSchoolData(lwpSchoolId: $lwpSchoolId, wondeSchoolId: $wondeSchoolId)
    }
`;

const linkLwpClassToWondeClass = gql`
    mutation linkLwpClassToWondeClass($input: LinkClassInput!) {
        linkLwpClassToWondeClass(input: $input)
    }
`;

const linkPupilToWondePupil = gql`
    mutation linkPupilToWondePupil($input: LinkPupilInput!) {
        linkPupilToWondePupil(input: $input)
    }
`;

const addActivity = gql`
    mutation addActivity($file: [Upload!], $input: ActivityInput!) {
        addActivity(file: $file, input: $input) {
            id
        }
    }
`;

const addModal = gql`
    mutation addModal($file: [Upload!], $input: ModalInput!) {
        addModal(file: $file, input: $input) {
            id
        }
    }
`;

const updateModal = gql`
    mutation updateModal($file: [Upload!], $input: UpdateModalInput!) {
        updateModal(file: $file, input: $input) {
            id
        }
    }
`;

const removePupilFromClass = gql`
    mutation removePupilFromClass($pupilId: String!, $classId: String!) {
        removePupilFromClass(pupilId: $pupilId, classId: $classId) {
            id
        }
    }
`;

const changePupilClass = gql`
    mutation changePupilClass($pupilId: String!, $newClassId: String!) {
        changePupilClass(pupilId: $pupilId, newClassId: $newClassId)
    }
`;

const savePupilFeedbackRating = gql`
    mutation savePupilFeedbackRating($id: String!, $rating: Float!) {
        savePupilFeedbackRating(id: $id, rating: $rating) {
            id
        }
    }
`;

const setSchoolPeriodToCurrent = gql`
    mutation setSchoolPeriodToCurrent($schoolId: String!) {
        setSchoolPeriodToCurrent(schoolId: $schoolId) {
            id
        }
    }
`;

const saveDemoActivityPhotoRating = gql`
    mutation saveDemoActivityPhotoRating($id: String!, $photoRating: Float!) {
        saveDemoActivityPhotoRating(id: $id, photoRating: $photoRating) {
            id
        }
    }
`;

const addPupilMathscot = gql`
    mutation addPupilMathscot($input: AddPupilMathscotInput!) {
        addPupilMathscot(input: $input) {
            id
        }
    }
`;

const addAFile = gql`
    mutation addAFile($download: Boolean!, $file: [Upload!]!) {
        addAFile(download: $download, file: $file)
    }
`;

const deleteAFile = gql`
    mutation deleteAFile($id: String!) {
        deleteAFile(id: $id) {
            status
        }
    }
`;

const submittedInterstitialForm = gql`
    mutation submittedInterstitialForm($input: SubmitInterstitialInput!) {
        submittedInterstitialForm(input: $input)
    }
`;

const addInterstitialForm = gql`
    mutation addInterstitialForm($input: AddInterstitialInput!) {
        addInterstitialForm(input: $input) {
            id
        }
    }
`;

const updateInterstitialForm = gql`
    mutation updateInterstitialForm($input: UpdateInterstitialInput!) {
        updateInterstitialForm(input: $input) {
            id
        }
    }
`;

const deleteInterstitialForm = gql`
    mutation deleteInterstitialForm($id: String!) {
        deleteInterstitialForm(id: $id) {
            status
        }
    }
`;

const releaseInterstitialForm = gql`
    mutation releaseInterstitialForm($id: String!) {
        releaseInterstitialForm(id: $id)
    }
`;

const resetInterstitialForm = gql`
    mutation resetInterstitialForm($id: String!) {
        resetInterstitialForm(id: $id)
    }
`;

const deleteTeacher = gql`
    mutation deleteTeacher($id: String!) {
        deleteTeacher(id: $id) {
            status
            msg
        }
    }
`;

const saveDemoActivityFeedback = gql`
    mutation saveDemoActivityFeedback($file: [Upload!], $input: DemoActivityFeedbackInput!) {
        saveDemoActivityFeedback(file: $file, input: $input) {
            id
        }
    }
`;

// const saveReadingNote = gql`
//     mutation saveReadingNote($readingNote: String!, $classTopicId: String!, $readingTarget: Float!) {
//         saveReadingNote(readingNote: $readingNote, classTopicId: $classTopicId, readingTarget: $readingTarget) {
//             id
//         }
//     }
// `;

const saveReadingNote = gql`
    mutation saveReadingNote($input: ReadingNoteInput!) {
        saveReadingNote(input: $input) {
            id
        }
    }
`;

const deleteStaticTrophy = gql`
    mutation deleteStaticTrophy($id: String!) {
        deleteStaticTrophy(id: $id) {
            status
            msg
        }
    }
`;

const adminReleasePupilTopics = gql`
    mutation adminReleasePupilTopics($date: DateTime) {
        adminReleasePupilTopics(date: $date)
    }
`;

const adminCreateThisWeekReadingTopic = gql`
    mutation adminCreateThisWeekReadingTopic($classId: String!) {
        adminCreateThisWeekReadingTopic(classId: $classId)
    }
`;

const logout = gql`
    mutation logout($refreshToken: String!) {
        logout(refreshToken: $refreshToken)
    }
`;

const addPupilsInClass = gql`
    mutation addPupilsInClass($classId: String!, $pupilIds: [String!]!) {
        addPupilsInClass(input: { classId: $classId, pupilIds: $pupilIds })
    }
`;

const addParameter = gql`
    mutation addParameter($input: ParameterInput!) {
        addParameter(input: $input) {
            key
        }
    }
`;

const updateParameter = gql`
    mutation updateParameter($input: ParameterInput!) {
        updateParameter(input: $input) {
            key
        }
    }
`;

const deleteParameter = gql`
    mutation deleteParameter($id: String!) {
        deleteParameter(id: $id) {
            status
            msg
        }
    }
`;

const removePupilFromSchool = gql`
    mutation removePupilFromSchool($pupilId: String!) {
        removePupilFromSchool(pupilId: $pupilId)
    }
`;

const changeSchoolPeriod = gql`
    mutation changeSchoolPeriod($index: Float!, $schoolId: String!) {
        changeSchoolPeriod(index: $index, schoolId: $schoolId) {
            id
        }
    }
`;

const rejectInviteConfirmation = gql`
    mutation rejectInviteConfirmation($token: String!) {
        rejectInviteConfirmation(token: $token)
    }
`;

const trophiesAssignedToPupils = gql`
    mutation trophiesAssignedToPupils {
        trophiesAssignedToPupils
    }
`;

const adminSendReleasedPupilTopicNotifications = gql`
    mutation adminSendReleasedPupilTopicNotifications {
        adminSendReleasedPupilTopicNotifications
    }
`;

const adminTeacherTopicsToBeReleasedTomorrow = gql`
    mutation adminTeacherTopicsToBeReleasedTomorrow {
        adminTeacherTopicsToBeReleasedTomorrow
    }
`;

const adminTopicReminderAfter7Days = gql`
    mutation adminTopicReminderAfter7Days {
        adminTopicReminderAfter7Days
    }
`;

const adminSendTrophyMailToPupil = gql`
    mutation adminSendTrophyMailToPupil {
        adminSendTrophyMailToPupil
    }
`;

const adminSendTrophyMailToTeacher = gql`
    mutation adminSendTrophyMailToTeacher {
        adminSendTrophyMailToTeacher
    }
`;

const adminSendTrophyMailToSchoolLeader = gql`
    mutation adminSendTrophyMailToSchoolLeader {
        adminSendTrophyMailToSchoolLeader
    }
`;

const readingTopicReminders = gql`
    mutation readingTopicReminders {
        readingTopicReminders
    }
`;

const sendTeacherReadingTopicEnd = gql`
    mutation sendTeacherReadingTopicEnd {
        sendTeacherReadingTopicEnd
    }
`;

const adminlogsCleanUp = gql`
    mutation adminlogsCleanUp {
        adminlogsCleanUp
    }
`;
const changeClassCreationStatusForSchools = gql`
    mutation changeClassCreationStatusForSchools($hide: Boolean!) {
        changeClassCreationStatusForSchools(hide: $hide)
    }
`;

const adminCreateReadingTopics = gql`
    mutation adminCreateReadingTopics {
        adminCreateReadingTopics
    }
`;

const reimportSchoolsWondeData = gql`
    mutation reimportSchoolsWondeData {
        reimportSchoolsWondeData
    }
`;
const runHourlySqlQueries = gql`
    mutation runHourlySqlQueries {
        runHourlySqlQueries
    }
`;
const runNightlySqlQueries = gql`
    mutation runNightlySqlQueries {
        runNightlySqlQueries
    }
`;

const updateNoLinkedParentStatusOfPupils = gql`
    mutation updateNoLinkedParentStatusOfPupils {
        updateNoLinkedParentStatusOfPupils
    }
`;

const registerUserEmail = gql`
    mutation registerUserEmail($input: RegisterUserEmailInput!) {
        registerUserEmail(input: $input) {
            id
        }
    }
`;

const reInviteParent = gql`
    mutation reInviteParent($parentId: String!) {
        reInviteParent(parentId: $parentId) {
            id
        }
    }
`;

const reGenerateMagicLink = gql`
    mutation reGenerateMagicLink($token: String!) {
        reGenerateMagicLink(token: $token) {
            url
        }
    }
`;

const removeWondeAndUnusedLwpDataFromWonde = gql`
    mutation removeWondeAndUnusedLwpDataFromWonde($lwpSchoolId: String!) {
        removeWondeAndUnusedLwpDataFromWonde(lwpSchoolId: $lwpSchoolId)
    }
`;

const addTrophyInPupil = gql`
    mutation addTrophyInPupil($pupilId: String!, $trophyId: String!, $classId: String!) {
        addTrophyInPupil(pupilId: $pupilId, trophyId: $trophyId, classId: $classId) {
            id
        }
    }
`;

export const logTooltipAction = gql`
    mutation logTooltipAction($userId: String!, $type: TooltipType!) {
        logTooltipAction(userId: $userId, type: $type)
    }
`;

export {
    addPupilForParent,
    signup,
    updatePupil,
    updateParent,
    addClassTopic,
    updateClassTopic,
    deleteClassTopic,
    previewPupilMathscotForEditMathscot,
    mergeMathscotItems,
    createStaticTrophy,
    updateStaticTrophy,
    addTeacher,
    createSchoolClass,
    likePupilActivityFeedback,
    savePupilActivityRating,
    updateTeacher,
    updateSchoolClass,
    updateSchoolClassReadingSettings,
    updateSchool,
    changePassword,
    savePhotoPermissions,
    changeClassPupilsPremium,
    inviteParentWithPupil,
    increaseAccessCount,
    saveOwnPupilActivityFeedback,
    addContact,
    addAdmin,
    addSchool,
    addAdminQuery,
    updateAdminQuery,
    deleteAdminQuery,
    addFAQ,
    updateFAQ,
    deleteFAQ,
    deleteNews,
    addNews,
    updateNews,
    addStaticPeriod,
    addYearGroup,
    updateStaticPeriod,
    updateYearGroup,
    deleteStaticPeriod,
    removePupilsFromSchool,
    deleteYearGroup,
    acceptUserInvitation,
    acceptOrRejectPupilInvitation,
    forgotPassword,
    resetPasswordByToken,
    addResource,
    resetUserPassword,
    addTopic,
    updateTopic,
    // verifyEmailAndPhoneById,
    generateCertificates,
    sendNotification,
    sendTestEmail,
    downloadAllClassesCSV,
    removeParentFromPupil,
    inviteParentsOfPupils,
    inviteParentOfPupil,
    pullWondeSchoolData,
    linkLwpClassToWondeClass,
    linkPupilToWondePupil,
    addActivity,
    removePupilFromClass,
    changePupilClass,
    savePupilFeedbackRating,
    saveDemoActivityPhotoRating,
    setSchoolPeriodToCurrent,
    addPupilMathscot,
    addAFile,
    deleteAFile,
    submittedInterstitialForm,
    addInterstitialForm,
    updateInterstitialForm,
    deleteInterstitialForm,
    releaseInterstitialForm,
    resetInterstitialForm,
    deleteTeacher,
    saveDemoActivityFeedback,
    saveReadingNote,
    deleteStaticTrophy,
    adminReleasePupilTopics,
    logout,
    addPupilsInClass,
    addParameter,
    updateParameter,
    deleteParameter,
    removePupilFromSchool,
    changeSchoolPeriod,
    rejectInviteConfirmation,
    registerUserEmail,
    reInviteParent,
    updatePupilMathscot,
    deleteModal,
    addModal,
    updateModal,
    inviteTeacher,
    updateAdmin,
    removeWondeAndUnusedLwpDataFromWonde,
    reGenerateMagicLink,
    addTrophyInPupil,
    resetUsersSavedPermissions,
    trophiesAssignedToPupils,
    deletePupilTrophy,
    adminSendReleasedPupilTopicNotifications,
    adminTeacherTopicsToBeReleasedTomorrow,
    adminTopicReminderAfter7Days,
    adminSendTrophyMailToPupil,
    adminSendTrophyMailToTeacher,
    adminSendTrophyMailToSchoolLeader,
    adminlogsCleanUp,
    adminCreateReadingTopics,
    adminCreateThisWeekReadingTopic,
    addSchoolDate,
    updateSchoolDate,
    deleteSchoolDate,
    addSchoolHoliday,
    updateSchoolHoliday,
    releaseHolidayToAllSchools,
    softDeleteSchoolHoliday,
    restoreSchoolHoliday,
    changeClassCreationStatusForSchools,
    refreshAllDataQueries,
    readingTopicReminders,
    sendTeacherReadingTopicEnd,
    reimportSchoolsWondeData,
    runHourlySqlQueries,
    runNightlySqlQueries,
    updateNoLinkedParentStatusOfPupils
};
