import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
    wrapper: {
        // margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
});

const LWPButton = ({
    btnText,
    disabled,
    loading,
    onClick,
    customClass,
    size,
    fullWidth,
    variant,
    color,
    endIcon,
    startIcon,
    disableRipple,
    type,
    iconClass,
    children,
    dataCy,
    style,
    href,
    sx,
    dataTest
}) => {
    const classes = useStyles();

    return (
        <span className={`${classes.wrapper} lwp-button-wrapper ${disabled && 'lwp-button-disabled'}`}>
            <Button
                style={style}
                data-cy={dataCy}
                size={size}
                className={customClass ? `${customClass} lwp-button` : 'lwp-button'}
                variant={variant}
                color={color}
                fullWidth={fullWidth}
                onClick={onClick}
                disabled={disabled || loading}
                type={type}
                href={href}
                startIcon={startIcon ? <span className={`material-icons ${iconClass}`}>{startIcon}</span> : ''}
                endIcon={endIcon ? <span className={`material-icons ${iconClass}`}>{endIcon}</span> : ''}
                disableRipple={disableRipple}
                data-test={dataTest}
                sx={sx}>
                {children || btnText}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </span>
    );
};

LWPButton.propTypes = {
    color: PropTypes.string,
    variant: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    startIcon: PropTypes.string,
    endIcon: PropTypes.string,
    btnText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    disableRipple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    iconClass: PropTypes.string,
    dataCy: PropTypes.string,
    children: PropTypes.element,
    style: PropTypes.object,
    href: PropTypes.string,
    dataTest: PropTypes.string,
    sx: PropTypes.object
};
LWPButton.defaultProps = {
    color: 'primary',
    size: 'large',
    variant: 'contained',
    btnText: 'Button Name'
};
export default LWPButton;
