import cookie from 'cookie';
import JSCookie from 'js-cookie';
import jwt_decode from 'jwt-decode';

let cookies;

/**
 * Sets a server-side cookie shim.
 * Used because JScookie does not work if we're serving content through Next's server.
 *
 * @param context the server context
 */
function setCookieShimFromContext(context) {
    cookies = {
        get: (key) => context.pendingCookies?.[key]?.value || context.req.cookies[key],
        set: (key, value) => {
            const date = new Date();

            date.setDate(date.getDate() + 90);

            return setCookieInContext(context, key, value, `Path=/; Expires=${date.toUTCString()}`);
        },
        delete: (key) => setCookieInContext(context, key, '', 'Path=/; Max-Age=-1')
    };
}

/**
 * Adds a cookie to the list of cookies to set in this request context.
 *
 * @param context the request context
 * @param key the cookie key
 * @param value the cookie value
 * @param cookieOpts the options for the cookie, as encoded in a string
 * @return the result of the setHeader
 */
function setCookieInContext(context, key, value, cookieOpts) {
    context.pendingCookies = context.pendingCookies ?? {};

    context.pendingCookies[key] = { value, cookieOpts };

    return context.res.setHeader(
        'Set-Cookie',
        Object.entries(context.pendingCookies).map(([cookieKey, pendingCookie]) => {
            return `${cookieKey}="${pendingCookie.value}"${pendingCookie.cookieOpts ? '; ' + cookieOpts : ''}`;
        })
    );
}

function parseCookies(req) {
    return cookie.parse(req ? req.headers.cookie || '' : document.cookie);
}

function setCookies(access_token, refresh_token) {
    if (access_token) setCookie('access_token', access_token);
    if (refresh_token) setCookie('refresh_token', refresh_token);
    setCookie('isInterstitial', true);
}

function setDemoActivityCookies(shareGlobal, shareWithParents, rememberSettings) {
    setCookie('share_with_parents', shareWithParents);
    setCookie('share_global', shareGlobal);
    setCookie('remember_settings', rememberSettings);
}

function clearDemoActivityCookies() {
    removeCookie('share_with_parents');
    removeCookie('share_global');
    removeCookie('remember_settings');
}

function getCookie(key) {
    if (!cookies) {
        return JSCookie.get(key);
    }

    return cookies.get(key);
}

function setCookie(key, value) {
    const cookieOptions = { expires: 90 };

    if (!cookies) {
        return JSCookie.set(key, value, cookieOptions);
    }

    return cookies.set(key, value, cookieOptions);
}

function removeCookie(key) {
    if (!cookies) {
        return JSCookie.remove(key);
    }

    return cookies.delete(key);
}

function clearCookies(push, setToken) {
    clearCookiesOnly();
    setToken(null);
    push('/');
}

function clearCookiesOnly() {
    removeCookie('access_token');
    removeCookie('refresh_token');
}

function getJwtDecoded(token) {
    return jwt_decode(token);
}

function isTokenExpired(expiration) {
    return Date.now() >= expiration * 1000;
}

export {
    parseCookies,
    setCookies,
    getCookie,
    setCookie,
    removeCookie,
    clearCookies,
    getJwtDecoded,
    isTokenExpired,
    setDemoActivityCookies,
    clearDemoActivityCookies,
    clearCookiesOnly,
    setCookieShimFromContext
};
